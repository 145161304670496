#caption {
    top: 5vh;
    text-shadow: 1px 2px 2px black;
}

p {
    font-size: 20px;
}

img {
    width: auto; 
    height: calc(100vh - 56px)
}

.logotec2{
    width: 40%;
    object-fit: contain;
    margin: 0px;
    margin-top: -260px;
    margin-bottom: -260px;
}

.logotec3{
    width: 120%;
    object-fit: contain;
    margin-bottom: 50px;
    margin-left: -25px;
    height: auto;
}

.c{
    color: white;
    text-shadow: 1px 1px 2px black;
    margin-bottom: 100px;
}

.c2{
    color: white;
    padding: 10px;
    text-shadow: 1px 1px 2px black;
}