li {
    font-size: 17px;
    color:white;
    text-shadow: 1px 1px 2px black;
    width: 70%;
    height: auto;
    margin: auto;
    padding-bottom: 20px;
    text-align: center;
    list-style: none;
}

h2 {
    color:white;
    text-align: center;
    font-weight: 500;
    font-size: 25px;
    text-shadow: 1px 1px 2px black;
    background-color: rgba(0,0,0,0.75);
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    padding: 10px;
    width: auto;
    height: auto;
    margin-left: -12px;
    margin-right: -12px;
}

h3 {
    color:white;
    font-weight: 500;
    font-size: 20px;
    text-shadow: 1px 1px 2px black;
    background-color: rgba(0,0,0,0.75);
    display: inline-block;
    width: 100%;
    padding: 10px;
    border-radius: 50px;
    height: auto;
    text-align: center;
    justify-content: center;
}


.tituloContato {
    margin-top: 15px;
    background-color: rgba(0,0,0,0.75);
    border-radius: 50px; 
    display: inline-block;
    padding: 20px;
    text-align: center;
    justify-content: center;
    padding-right: 50px;
    padding-left: 50px;
}

.mapa {
    object-fit: 'contain';
    width: 290px;
    height: 240px;
}

.mapa2 {
    object-fit: 'contain';
    width: 400px;
    height: 350px;
}

.cc{
    color: white;
    background-color: rgba(0,0,0,0.5);
    padding: 10px;
    width: 100%;
    text-shadow: 1px 1px 2px black;
    font-weight: 600;
    text-align: center;
}

.ccIcon{
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    text-shadow: 1px 1px 2px black;
    width: auto;
    height: auto;
    box-sizing: content-box;
}

.cc2{
    color: white;
    text-shadow: 1px 1px 2px black;
    width: 100%;
    font-size: 18px;
    margin: auto;
    text-align: center;
    padding: 2px;
    font-weight: 400;
}

.pt {
    width: 25px;
    object-fit: contain;
    box-sizing: border-box;
    height: 60px;
    margin-left: 10px;
}

.en {
    width: 25px;
    object-fit: contain;
    box-sizing: border-box;
    height: 60px;
}

.es {
    width: 25px;
    object-fit: contain;
    box-sizing: border-box;
    height: 60px;
    margin-left: -12px;
}

.logohead {
    object-fit: contain;
    width: 10%;
    box-sizing: content-box;
    margin: 0;
    border: 0;
    padding: 0;
    height: 60px;
    width: 100px;
}

.links {
    color: white;
    font-size: 17px;
    box-sizing: content-box;
    width: 100px;
    justify-content: left;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
}

.pin {
    object-fit: contain;
    width: 20px;
    margin-right: 10px;
    float: left;
}

.div {
    text-align: center;
    margin-left: auto; 
    margin-right: auto;
}

.imgBack {
    background-image: url(./images/C2.png);
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
}

.iconeDiv {
    height: auto; /* altura igual à altura da janela do navegador */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
}

.iconesContato {
    object-fit: contain;
    width: 30px;
    height: 30px;
    text-align: right;
    justify-content: left;
    margin-right: 10px;
    margin-bottom: 7px;
}

.atrasTexto {
    text-align: center;
    height: auto; /* altura igual à altura da janela do navegador */
    display: flex;
    justify-content: center;
    align-items: center;
}

.clientes {
    display: flex;
    width: auto;
    height: auto;
    justify-content: space-around;
}

.listaTitle {
    text-align: center;
    justify-content: center;
    color: white;
    background-color: rgba(0,0,0,0.75);
    padding: 10px;
    border-radius: 50px;
}

.dadosContato {
    background-color: rgba(0,0,0,0.5);
    padding: 10px;
    border-radius: 50px;
    width: 50%;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
}

.produtos {
    color: white;
}

.iconeDiv2 {
    height: auto; /* altura igual à altura da janela do navegador */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    margin-bottom: 15px;
}

.dadosProd {
    background-color: rgba(0,0,0,0.5);
    padding: 10px;
    border-radius: 40px;
    width: 70%;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.dadosProd2 {
    background-color: rgba(0, 0, 0, 0.85);
    padding: 10px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    width: 70%;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
}

.iconesProd {
    object-fit: contain;
    width: auto;
    height: 300px;
    text-align: right;
    justify-content: left;
    margin-bottom: 7px;
    margin-top: 10px;
}

.iconesProd2 {
    object-fit: contain;
    width: auto;
    height: 300px;
    text-align: right;
    justify-content: left;
}

  .fundoTitulo {
    text-align: center;
    justify-content: center;
    color: white;
    background-color: rgba(0,0,0,0.75);
    padding: 10px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -10px;
  }

 

  .tituloP {
    color: white;
    background-color: rgba(0,0,0,0.5);
    padding: 10px;
    border-radius: 50px;
    margin: 0px auto;
    width: 80%;
    text-align: center;
    justify-content: center;
  }

  .logocolorida {
    justify-content: center;
    text-align: center;
    object-fit: contain;
  }

  .imgColorida {
        display: block;
        margin: 0 auto;
        height: 100px;
        width: 300px;
        object-fit: contain;
        margin-top: 30px;
  }

  .fundoSobre {
    background-color: rgba(0,0,0,0.5);
    border-radius: 40px;
  }


                            @media (max-width: 768px) {
                                li {
                                    width: 100%;
                                    margin-left: -15px;
                                }

                                .li2 {
                                    width: 100%;
                                    margin-left: 0px;
                                }

                                .dadosProd {
                                    width: 100%;
                                }

                                .iconesProd2 {
                                    object-fit: contain;
                                    width: 350px;
                                    height: auto;
                                    text-align: right;
                                    justify-content: left;
                                    margin-bottom: 7px;
                                }

                                .iconesProd {
                                    object-fit: contain;
                                    width: auto;
                                    height: 200px;
                                    text-align: right;
                                    justify-content: left;
                                    margin-bottom: 7px;
                                }

                                .dadosContato {
                                    width: 100%;
                                }

                                .imgColorida {
                                    display: block;
                                    margin: 0px auto;
                                    height: 100px;
                                    width: 300px;
                                    object-fit: contain;
                                    margin-top: 30px;
                                }

                                .tituloP {
                                    color: white;
                                    background-color: rgba(0,0,0,0.5);
                                    padding: 10px;
                                    border-radius: 50px;
                                    margin: 0px auto;
                                    width: 100%;
                                    text-align: center;
                                    justify-content: center;
                                }
  }